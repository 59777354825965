.review {
  margin-top: 5rem;
}
.cardClass {
  display: flex;
  justify-content: space-between;
  padding: 5rem;
  margin-right: 10rem;
  height: 100%;
  max-height: 60vh;
  gap: 5rem;
}
.right {
  display: flex;
  flex-direction: column;
  padding: 1;
}
.right h4 {
  padding-bottom: 1rem;
}
textarea {
  width: 100%;
}
.btn {
  background-color: blue;
}
.btn:hover {
  background-color: white;
  border: none;
}


/* MEDIA QUERY */
/* THIS IS TARGETTING DEVICES WHOSE SCREEN SIZE IS > 640PX, BUT < 1020PX. TABLETS*/
@media screen and (min-width:640px) and (max-width:1020px){
  .review {
    width: 100%;
  }

  .cardClass {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: -10rem;
    height: 100%;
    max-height: 46rem;
    gap: 0rem;
  }
  .image {
    width: 100%;
    height: 25rem;
    margin-top: 5rem;
  }
  .image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .right {
    width: 100%;
    margin-top: -1rem;
  }
  .right h4 {
    padding-bottom: 1rem;
    width: 100%;
  }
  .ratingContainer {
    width: 100%;
  }

  textarea {
    width: 100%;
  }
  .action {
    text-align: center;
  }
  .btn {
    background-color: blue;
  }
}


/* MEDIA QUERY */
/* THIS IS TARGETTING PHONES WHOSE SCREEN SIZE IS > 360PX, BUT < 640PX.ALL PHONES EXCEPT; SAMSUNG GALAXY S8 PLUS */
@media screen and (min-width: 360px) and (max-width: 640px) {
  .review {
    width: 100%;
    /* margin-top: 0; */
  }

  .cardClass {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: -10rem;
    height: 100%;
    max-height: 50rem;
    gap: 0rem;
  }
  .image {
    width: 100%;
    height: 25rem;
    margin-top: 5rem;
  }
  .image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .right {
    width: 100%;
    margin-top: -1rem;
  }
  .right h4 {
    /* padding-bottom: 1rem; */
    margin-top: 2rem;
    width: 100%;
  }
  .right p{

  }
  .ratingContainer {
    width: 100%;
  }

  textarea {
    width: 100%;
    
  }
  .action {
    text-align: center;
  }
  .btn {
    background-color: blue;
  }
}
/* MEDIA QUERY */
/* THIS IS TARGETTING PHONES WHOSE SCREEN SIZE IS < 360PX. PHONES LIKE :SAMSUNG GALAXY S8 PLUS */
@media screen and (max-width: 360px) {
  .review {
    width: 100%;
  }

  .cardClass {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: -10rem;
    height: 100%;
    max-height: 50rem;
    gap: 0rem;
  }
  .image {
    width: 100%;
    height: 25rem;
    margin-top: 5rem;
  }
  .image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .right {
    width: 100%;
    margin-top: 1rem;
  }
  .right h4 {
    /* padding-bottom: 1rem; */
    margin-top: 1rem;
    width: 100%;
  }
  .ratingContainer {
    width: 110%;
  }
  /* .ratingContainer > *{
    font-size: 0.5rem;
  } */
    
  textarea {
    width: 100%;
    
  }
  .btn{
    margin-top: 1rem;
  }
}

