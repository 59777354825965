.header {
  background-color:white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* padding: 5rem 10%; */
  padding: 3rem 10%;
  width: 100%;
  height: 5rem;
  position: relative;
  top: 0;
  position: fixed;
  z-index: 800;
  
}

.logo a {
  text-decoration: none;
}

.header nav {
  display: flex;
}
.admin{
  padding: 1rem;
  background-color: black;
  border-radius: 6px;
}
.admin a{
  color: white;
}

.shop-now{
  padding: 1rem;
  background-color: black;
  border-radius: 6px;
  margin-left: 1rem;
}
.shop-now a{
  color: white;
}

.header ul {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0;
  list-style: none;
}
.header ul a {
  margin-left: 1rem;
  text-decoration: none;
}
.cart a {
  display: flex;
  align-content: center;
  justify-content: center;
  margin-left: 1rem;
  text-decoration: none;
}
a:hover {
  color: yellow;
}
.mobile-icon{
  display: none;
}

.mobile-head-main {
  display: none;
}

.active{
  color: red;
}


/* MEDIA QUERY 
FOR TABLETS AND PHONES TURNED DIAGONALLY/LANDSCAPE{LARGE PHONES}. PHONES LIKE: iphoneXR, iphone12PRO, pixel5, samsung galaxy S20 */
@media screen and (min-width:810px) and (max-width:1024px){
  .header{
    margin-left: -3rem;
  }
  .header li a {
    font-size: 1.2rem;
  }
  .shop-now{
    width: 9rem;
  }
  
}
/* MEDIA QUERY 
FOR TABLETS  BUT NONE FIT INTO THIS CAREGORY INSTEAD ITS FOR SOME GROUP OF PHONES TURNED DIAGONALLY/LANDSCAPE. PHONES LIKE: iphoneSE and Samsung galaxy S8+*/
@media screen and (min-width:640px) and (max-width:810px){
  .header{
    margin-left: -3rem;
  }
  .logo{
    font-size: 0.7rem;
  }
  .header li a {
    font-size: 1rem; 
  }
  .shop-now{
    width: 8rem;
  }
  
}
/* MEDIA QUERY 
FOR EVERY PHONE BELOW 640PX WHEN STANDING STRAIGHT/PORTRAIT/VERTICAL AND NOT DIAGONALLY*/
@media screen and (max-width: 640px) {
.logo{
  font-size: 0.7rem;
}
.search{
  display: none;
}
.spanCart{
  display: none;
}
 nav{
  display: block;
  position: absolute;
  top: 6rem;
  left: 0;
  width: 50%;
  height: 100vh;
  background-color: black;
  padding: 1rem;
  transform: translateX(-200%);
  transition: all 0.3s;
  z-index: 10;
}
.nav-backdrop{
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100vh;
  transform: translateX();
  transition: all 0.3s;
  background-color: rgba(0, 0, 0, 0.7);
}
.show-nav-backdrop{
  transform: translateX(100%);
} 
.header{
  width: 100%;
  /* padding-right: 5rem; */
}
.header ul{
  display: block;
}
.header li{
  padding: 1rem 0;
  border-bottom: 1px solid white;
}
.header ul a{
  color: white;
  font-size: 1.5rem;
  display: block;
}
.show-navigation{
  transform: translateX(0);
}
.hide-navigation{
  transform: translate(-200%);
}
.mobile-icon{
  display: flex;
  align-items: center;
  gap: 1.5rem;
}

};






