.container{
    display: flex;
    /* align-items: center; */
    justify-content: space-between;
    gap: 2rem;
}

.checkout-details{
    width: 70%;
    max-width: 30rem;
}
.checkout-summary{
    width: 70%;
    max-width: 30rem;
}
.itemCard{
    height: 10rem; 
    overflow-y: scroll;  
}
.control{
    padding: 1rem;
}
.control label{
    display: block;
    margin-bottom: 0.5rem;
}
.control input{
    display: block;
    width: 100%;
    border-radius: 6px;
    font: inherit;
    padding: 0.5rem;
    border: 1px solid #ccc;
}
.action{
    display: flex;
    align-items: center;
    justify-content: right;
    gap: 1rem;
    margin-bottom: 0.5rem;
    padding-bottom: 0.5rem;
}
.btn:hover{
    background-color: white;
    border: none;
}

.invalid label{
    color: #ca3e51;
}
.invalid input{
    border-color: #aa0b20;
    background-color: #ffeff1;
}
.checkout-summary .item{
    padding: 1rem;
}
.checkout-summary .item h4{
    margin-bottom: 1rem;
}


@media screen and (min-width:640px) and (max-width:1024px){
    .container{
        display: flex;
        flex-direction: column-reverse;
        gap: 2rem;
        align-items: center;
    }
    .checkout-details > h2{
        text-align: center;
        margin-top: 2rem;
    }
    .control label{
        font-size: 1.5rem;
    }
    .control input{
        font: inherit;
        font-size: 1.5rem;
    }
    .action{
        display: flex;
        justify-content: left;
    }
    .action .btn{
        font-size: 1.5rem;
    }
    .checkout-summary{
        width: 100%;
        padding: 1.5rem;
    }
    .lead{
        padding: 2rem;
        text-align: left;
    }
    .itemCard{
        padding: 1.5rem;
    }
}


@media screen and (max-width:640px){
    .container{
        display: flex;
        flex-direction: column-reverse;
        gap: 2rem;
    } 
    .checkout-details{
        width: 100%;
    }
    .checkout-details > h2{
        text-align: center;
    }
    .action{
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .checkout-summary{
        width: 100%;
        padding: 1.5rem;
    }
    .lead{
        padding: 2rem;
        text-align: left;
    }  
}

