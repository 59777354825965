.pagination ul{
    display: flex;
    justify-content: space-around;
    padding: 1rem;
    list-style: none;
}
.pagination:hover{
    cursor: pointer;
}
.pagination ul li{
    background-color: black;
    color: white;
    padding: 0.4rem 0.8rem;
    border-radius: 6px;
}
.pagination button{
    background-color: black;
    color: white;
    padding: 0.4rem 0.8rem;
    border-radius: 6px;
}
.pagination .active{
    background-color: whitesmoke;
    border: none;
    color: black;
}
.hidden{
    display: none;
}

@media  screen and (max-width:640px){
    .pagination ul{
        display: flex;
        padding: 1rem;
        list-style: none;
        gap: 0.5rem;
    }
    .pagination button{
        background-color: black;
        color: white;
        padding: 0.1rem 0.25rem;
        border-radius: 6px;
    }
}