.topClass{
    display: flex;
    justify-content: space-between;
    padding:0 2rem;
    gap: 4rem;
    width: 100%;    
}
.BarCharts{
    display: flex;
    justify-content: space-between;
    gap: 2rem;
    margin-top: 3rem;
}
