.products{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    
}

.products .cardClass{
    width: 15rem;

    height: 25rem;
    padding: 1rem;
    margin: 10px;
    text-align: center;
    position: relative;
}
.productItemTop{
    margin-left: 5rem;
    padding-bottom: 1rem;
}

.image{
    /* img parent container */
    width: 100%;
    height: 15rem;
    overflow: hidden;
}
.image img{
    /* image */
    width: 100%;
    height: 100%;
    
    object-fit: cover;
}
.content{
    text-align: center;
}
.content p{
    color: red;
}
.btn{
    width: 100%;
    padding: 0.5rem 1rem;
    margin-bottom: 1rem;
    /* text-align: center; */
}
.btn:hover{
    background-color: white;
    color: black;
    border: none;
}



@media screen and (max-width:640px){
    .productItemTop{
        margin-top: 10rem;
        margin-left: 0;
    }
}

































