.backdrop{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 200;
    background-color: rgba(0, 0, 0, 0.7);
}

.modal header{
    text-align: center;
    background-color: black;
    color: white;
    padding: 0.5rem;
}
.modal{
    
    background-color: white;
    z-index: 300;
    width: 20rem;
    position: fixed;
    top: 30%;
    left: 40%;
}
.modal p{
text-align: center;
padding: 1rem;    
}
.footer{
    /* padding: 0.5rem; */
    background-color: black;
    color: white;
    text-align: center;
}
.footer Button{
    text-align: center;
    margin-bottom: 1rem;
}

@media screen and (min-width:640px) and (max-width:1024px){
    .modal{
    
        background-color: white;
        z-index: 300;
        width: 30rem;
        position: fixed;
        top: 30%;
        left: 20%;
    }   
}

@media screen and (max-width:640px){
    .modal{
        background-color: white;
        z-index: 300;
        width: 20rem;
        position: fixed;
        top: 30%;
        left: 9%;
    }   
}

@media screen and (max-width:360px){
    .modal{
        background-color: white;
        z-index: 300;
        width: 20rem;
        position: fixed;
        top: 30%;
        left: 5%;
    }
}