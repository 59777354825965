.btn {
  width: 12rem;
}
.btn:hover {
  background-color: white;
  border: none;
  color: black;
}

.btn a {
  color: white;
  text-decoration: none;
}
.btn a:hover {
  color: black;
}

.cardClass {
  display: flex;
  width: 100%;
  position: relative;
}
.cardClass2 {
  width: 80%;
}
.content {
  padding-left: 3rem;
  width: 60%;
}
.content div {
  margin-bottom: 2rem;
}
.brand {
  display: flex;
  gap: 1rem;
}
.cartBtn {
  width: 100%;
  padding: 0.5rem 1rem;
  /* text-align: center; */
}
.cartBtn:hover {
  background-color: white;
  color: black;
  border: none;
}
.descriptionTitle {
  text-align: left;
}
.descriptionContent {
  padding: 1rem;
}

.otherImages {
  display: flex;
  margin-left: 5rem;
}
/* .counter {
  display: flex;
  gap: 1rem;
  position: relative;
} */
.counter {
  display: flex;
  align-items: center;
}

.counter > * {
  margin-right: 1rem;
  margin-top: 1rem;
  /*This adds a right margin of 1rem{right & top} to the direct children of counter. */
}

.counter .btn {
  text-align: center;
}
/* .counter p {
  position: absolute;
  top: 2.2rem;
  left: 9.7rem;
} */
.reviewCardClass {
  padding: 2rem;
}
.rev p {
  padding: 0.3rem;
}

@media screen and (min-width:640px) and (max-width:1024px){
  .detail{
    overflow-x: hidden;
  }
  .detail h2 {
    margin-left: 5rem;
    font-size: 2rem;
  }
  a .btn {
    margin-left: 5rem;
    width: 10rem;
  }
  .cardClass {
    display: flex;
    flex-direction: column;
    margin-left: 3rem;
    width: 100%;
    overflow-x: hidden;
  }
  
  .content{
    margin-top: 3rem;
  }
  .content h2{
    /* text-align: center; */
    display: flex;
    justify-content: center;
    margin-left: 0;
  }
  .brand{
    display: flex;
    justify-content: center;
  }
  .brand h2,b{
    text-align: center;
    font-size: 1.5rem;
  }
  .cardClass2 {
    width: 100%;
    margin-left: 3rem;
  }
  .cardClass2 p{
    font-size: 1.5rem;
  }
}


@media screen and (max-width: 640px) {
  .detail h2 {
    margin-left: 1rem;
    font-size: 2rem;
  }
  a .btn {
    margin-left: 1rem;
    width: 10rem;
  }
  .cardClass {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .images .main-image {
    width: 90%;
    /* height: 15rem; */
    height: 25rem;
    margin-left: 0rem;
    margin-top: 2.5rem;
  }
  .main-image img {
    width: 100%;
    height: 100%;
    /* object-fit: cover; */
  }
  .otherImages {
    margin: 2rem 0;
  }
  .review {
    width: 100%;
  }
  .reviewCardClass {
    width: 95%;
  }
  .content {
    margin-top: 3rem;
    width: 95%;
  }
  .brand {
    position: absolute;
    top: 1rem;
    left: 2rem;
  }
  .cardClass2 {
    width: 100%;
    padding: 1.5rem;
  }
  .descriptionContent {
    width: 100%;
    padding: 1.5rem;
  }
  /* .counter p {
    font-size: 2rem;
    position: absolute;
    top: 1.5rem;
    left: 7.6rem;
  } */
  .counter p {
    font-size: 1rem;
    
  }
}
