.slider {
  position: relative;
  width: 100%;
  height: 40vh;
  margin-top: 3rem;
}

.slide {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transition: all 0.5s ease;
  transform: translateX(10%);
}


.slide > div {
  display: flex;
  align-items: center;
}
.slide h1{
  text-align: left;
  margin-left: 0;
}

.slide img {
  width: 45%;
  /* width: 65%; */
}


.btn {
  width: 12rem;
}
.btn:hover {
  background-color: white;
  border: none;
  color: black;
}

.btn a {
  color: white;
  text-decoration: none;
}
.btn a:hover {
  color: black;
}

.arr {
  z-index: 30;
}
.prev {
  position: absolute;
  top: 10rem;
  left: 0;
  color: orange;
}
.next {
  position: absolute;
  top: 10rem;
  right: 0;
  color: green;
}

/* MEDIA QUERY FOR TABLETS  */
@media screen and (min-width:640px) and (max-width:1024px){
  .slider {
    position: relative;
    width: 100%;
    height: 80vh;
    margin-top: 3rem;
  }
  
  .slide {
   width: 90%;
   height: 100%;
   position: absolute;
   top: 1rem;
   left: 0;
   transition: all 0.5s ease;
   transform: translateX(10%);
 }
 .prev {
   position: absolute;
   top: 15rem;
   left: 0;
   color: orange;
 }
 .next {
   position: absolute;
   top: 15rem;
   right: 0;
   color: green;
 }
 .slideFirstDiv {
   display: flex;
   /* flex-direction: column; */
   width: 100%;
   text-align: center;
 }
 .slide img {
  width: 60%;
}
 .slideFirstDiv p {
   text-align: left;
   /* inline-size: 450px; */
   inline-size: 250px;
   font-size: 1.5rem;
   width: 40%;
 }
 .btnContainer{
  display: flex;
  justify-content: flex-start;
}
.btn {
  width: 12rem;
} 
 }


@media screen and (max-width: 640px) {
  .slider {
    position: relative;
    width: 100%;
    /* height: 100vh; */
    height: 80vh;
    margin-top: -7rem;
  }
  .slide {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 10rem;
    left: 0;
    transition: all 0.5s ease;
    transform: translateX(5%);
  }
  .slide img {
    width: 80%;
  }
  .slideFirstDiv {
    display: flex;
    flex-direction: column;
  }
  .slideFirstDiv {
    text-align: center;
  }
  .slideFirstDiv p {
    text-align: left;
    inline-size: 250px;
    font-size: 1.5rem;
  }
  /* .next {
    position: absolute;
    top: 15rem;
    right: 2rem;
    color: green;
  } */
  .btnContainer{
    display: flex;
    justify-content: start;
  }
  .btn {
    width: 12rem;
  }
  .prev {
    position: absolute;
    top: 20rem;
    left: 0;
    color: orange;
  }
  .next {
    position: absolute;
    top: 20rem;
    right: 0;
    color: green;
  }       
}
