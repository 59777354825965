.container {
  width: 100%;  
}
.product {
  width: 65%;
  margin: 0;
  display: flex;
  padding: 1rem;
  gap: 3rem;
  
}

.image{
  width: 45%;
}
.image img{
  width: 100%;
}

.left {
  display: flex;
  flex-direction: column;
  width: 30rem;
}
.content p {
  padding: 0.5rem;
}

.right {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  width: 30rem;
  height: 5rem;
  margin-top: 10rem;
}

.counter{
  display: flex;
  align-items: center;
  
}
.counter > * {
  margin-right: 0.5rem;
  margin-top: 1rem;
  /*This adds a right margin of 1rem{right & top} to the direct children of counter. */
}



.clearcart-checkout {
  display: flex;
  justify-content: right;
  position: fixed;
  top: 7rem;
  right: 2rem;
  width: 25%;
}

.checkoutSummary {
  width: 30rem;
  padding: 2rem;
}
.subtotal {
  padding: 1rem;
}
.action {
  display: flex;
  justify-content: space-between;
}
.btn1 {
  color: red;
}

@media screen and (min-width:640px) and (max-width:1024px){
  .image {
    width: 100%;
  }
  .content p{
    font-size: x-large;
  }
  .left{
    margin-left: 5rem;
  }
  .right{
     display: flex;
    justify-content: flex-start;
    gap: 3rem;
  }
  .trash{margin-left: 5rem;}
  .btn, .counter p{
    margin-left: 1rem;
  }
  
  .btn{
    font-size: 1.5rem;
  }
  
}

@media screen and (max-width: 640px) {
  .container {
    width: 100%;
    overflow: scroll;
  }
  .container > p {
    text-align: center;
  }
  .product {
    width: 100%;
    display: flex;
    flex-direction: column;
    /* padding: 0rem; */
    gap: 0rem;
    overflow-x: hidden;
  }

  .right {
    width: 100%;
    margin-top: 0;
  }


  .btn {
    width: 5rem;
  }
  .clearcart-checkout {
    position: static;
    margin-top: 2rem;
    width: 100%;
    
  }
  .checkoutSummary{
    text-align: center;
    width: 100%;
  
  }
}


/* @media screen and (min-width:410px) and (max-width:640px){ */
@media screen and (min-width:640px) and (max-width:1200px){
  
  .container {
    width: 100%;
    overflow: scroll;
  }
  .container > p {
    text-align: center;
  }
  .product {
    width: 100%;
    display: flex;
    flex-direction: column;
    
    gap: 0rem;
    overflow-x: hidden;
  }

  .right {
    width: 100%;
    margin-top: 0;
  }


  .btn {
    width: 5rem;
  }
  .clearcart-checkout {
    position: static;
    margin-top: 2rem;
    width: 100%;
    
  }
  .checkoutSummary{
    text-align: center;
    width: 100%;
  
  }
};

