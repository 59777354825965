.checkout{
    margin: 6rem auto;
}
.checkout .content{
    text-align: center;
}
.action{
    display: flex;
    justify-content: center;
}
.btn{
    padding: 1rem;
    font-size: 0.9rem;
}
.btn:hover{
    background-color: white;
    border: none;
}

@media screen and (min-width:640px) and (max-width:1024px){
    .content{
        margin-top: 20rem;
    }
    .content p{
        font-size: 1.5rem;
    }
}