.card {
  padding: 1rem;
}
.control {
  margin-bottom: 0.5rem;
}
.control label {
  display: block;
  font-weight: bold;
  margin-bottom: 0.25rem;
}
.control input,
textarea {
  display: block;
  padding: 0.5rem;
  width: 100%;
  border-radius: 6px;
  border: 1px solid #aaa;
  font: inherit;
}

.progress {
  background-color: #aaa;
  border: 1px solid transparent;
  border-radius: 10px;
}
.progress-bar {
  background-color: var(--light-blue);
  border: 1px solid transparent;
  border-radius: 10px;
  color: #fff;
  font-size: 1.2rem;
  font-weight: 500;
  padding: 0 1rem;
}

.group {
    border: 1px solid var(--dark-blue);
    padding: 5px;
  }
.btn {
  text-align: center;
}
