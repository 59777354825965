.background{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 10;
    background-color: rgba(0, 0, 0, 0.7);
}
.background .image{
    position: fixed;
    top: 35%;
    left: 40%;
    background-color: transparent;
    z-index: 20;
}


@media screen and (min-width:640px) and (max-width:1020px){
    .background .image {
        top: 35%;
        left: 40%;
    }
    .image img{
        width: 15rem;
    }    
}

@media screen and (max-width:640px){
   .background .image {
        top: 50%;
        left: 35%;
    }
    .image img{
        width: 7rem;
    }
}
