.cardClass {
  display: flex;
  justify-content: space-between;
  width: 50rem;
  transition: transform 3s;
  /*transition: transform 3s; this allows the  card move back to its initial position at the same pace at which it moved before. And this was what moved it before:transition-duration: 3s; */
}
.cardClass:hover {
  transform: translateX(-200px);
  transition-duration: 3s;
}
.right {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.btn {
  padding: 1rem;
}
.btn:hover {
  border: none;
}
.backBtn{
  margin: 2rem 0rem;
  width: 12rem;
}
/* .image {
  width: 15rem;
}
.image img{
  width: 100%;
} */

@media screen and (min-width: 640px) and (max-width: 1020px) {
  
  .cardClass {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 50rem;
    margin: 6rem auto;
    
  }
  .order h2 {
    margin-left: 1rem;
  }
  .right {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    align-items: center;
    margin: 0;
  }
  .right h2 {
    margin-left: 0;
  }
  .action {
    margin-top: -2rem;
  }
  .backBtn {
    margin: 2rem 1rem;
  }
}

@media screen and (max-width: 640px) {
  .order {
    width: 90%;
    text-align: center;
    height: 100%;
    /* max-height: 45rem; */
  }
  .order h2 {
    font-size: 1.2rem;
    margin-left: 1rem;
  }
  .cardClass {
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 5rem 0;
    
  }
  .image {
    padding: 2rem;
  }
  .backBtn {
    margin: 2rem 0rem;
  }
}
