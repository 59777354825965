.routes{
    display: flex;
}
.nav{
    width: 15%;
    min-height: 80vh;
}
.content{
    width: 85%;
    padding: 1rem;
}




























