.top{
    display: flex;
    align-items: center;
    justify-content: space-around;
    gap: 3rem;
    margin-left: 10rem;
    border-bottom: 1px #eee solid;
    margin-bottom: 2rem;
    position: relative;
}

.search{
    text-align: right;
    padding-bottom: 2rem;
}
.filterToggle{
    display: none;
}
.sort{
    padding-bottom: 2.5rem;
    
}
.sort select{
    border: none;
    font-size: 1.2rem;
}

@media screen and (min-width:640px) and (max-width:1020px){
    .sort{
        margin-right: 10rem;        
    }
}

@media screen and (max-width:640px){
    .search {
        position: absolute;
        top: 3rem;
        /* left: -10rem; */
        left: -12rem;
    }
    .sort{
        position: absolute;
        top: 7rem;
        left: -10rem;
    }
    .filterToggle{
        display: block;
        padding: 0.5rem;
        position: absolute;  
        top: 3rem;
        /* left: 6rem; */
        left: 4rem;
        border-radius: 6px;
        z-index: 200;
    }
}

 





