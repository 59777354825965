 .navbar{
    border-right: 1px solid #ccc;
    min-height: 80vh; 
} 
.user{

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column; 
    padding: 4rem;
    background-color: black;
    
} 
h3{
    color: #fff;
}
.nav ul{
    padding: 0;
    margin: 0;
    list-style: none;
}
.nav ul li{
    border-bottom: 1px solid #ccc;
    padding: 1rem;
    list-style: none;
    position: relative;
    /* display: flex;
    align-items: center; */
}
.nav ul li a{
    display: block;
    width: 100%;
    list-style: none;
    text-decoration: none;
}

























