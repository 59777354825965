.backdrop{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 200;
    background-color: rgba(41, 31, 31, 0.1);
}
.modal{
    
    background-color: white;
    z-index: 300;
    width: 20rem;

    position: fixed;
    top: 20%;
    left: 40%;
}
.modal header{
    text-align: center;
    background-color: black;
    color: white;
    padding: 0.5rem;
}

.modal p{
text-align: center;
padding: 1rem;    
}
.footer{
    /* padding: 0.5rem; */
    background-color: black;
    color: white;
    text-align: center;
}
.footer Button{
    text-align: center;
    margin-bottom: 1rem;
}