.resetPassword{
    margin: 0rem auto;
    text-align: center;
}
.resetPassword h2{
    font-size: 2rem;
}
.resetPassword .control{
    width: 100%;
}
.resetPassword input{
    width: 50%;
    padding: 0.5rem;
    border-radius: 6px;
    border: #f4f1f1;
}
.icon:hover{
    cursor: pointer;
}
.action .btn{
    padding: 1rem;
}
.action .btn:hover{
    background-color: white;
    border: none;
}


@media screen and (max-width:640px){
    .resetPassword{
        margin-top: 6rem;
        height: 100%;
    }
}